import React, { useEffect, useMemo, useState } from 'react';
import { BaseModal, Button, Col, Icon, InputText, Row } from '../../ui';
import './ContactFormModal.scss';
import { Button as MuiButton, Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sendEmailVerification, sendPhoneVerification, verifyEmailCode, verifySmsCode } from '../../../api/contact';
import { SEND_CODE_TYPES } from '../../../pages/public/ContactFormPage';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { AppRoutes } from '../../../app/routers';

export const ContactFormModal = ({ open, onClose, sendCodeType, data, onFillContactForm }) => {
  const { t } = useTranslation();
  const [codes, setCodes] = useState({});
  const [resendLoading, setResendLoading] = useState(false);
  const history = useHistory();

  const closeModal = () => {
    setCodes({});
    onClose();
  };

  useEffect(() => {
    const initialCodes = _.reduce(
      sendCodeType,
      (result, acc) => {
        result[acc] = {
          value: '',
          verified: false,
        };
        return result;
      },
      {}
    );
    console.debug({ initialCodes });
    setCodes(initialCodes);
  }, [sendCodeType]);

  const style = {
    maxWidth: 400,
  };

  const onVerifyCode = async (type, value) => {
    console.log({ codes });
    try {
      const codeToVerity = value ? value : codes[type].value;
      if (type === SEND_CODE_TYPES.SMS) {
        await verifySmsCode(data, codeToVerity);
      }
      if (type === SEND_CODE_TYPES.EMAIL) {
        await verifyEmailCode(data, codeToVerity);
      }
      updateCodeVerified(type, true);
    } catch (e) {
      updateCodeVerified(type, false);
    }
  };

  const onSubmitDisabled = useMemo(() => {
    const isVerifiedValues = _.map(_.values(codes), 'verified');
    console.debug({ isVerifiedValues });
    return isVerifiedValues.includes(false);
  }, [codes]);

  const onResendVerificationCode = async () => {
    setResendLoading(true);
    sendCodeType.includes(SEND_CODE_TYPES.EMAIL) && (await sendEmailVerification(data));
    sendCodeType.includes(SEND_CODE_TYPES.SMS) && (await sendPhoneVerification(data));
    setResendLoading(false);
  };

  const onUpdateCodeValue = async (key, value) => {
    console.debug({ key, value });
    setCodes((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        value: value,
      },
    }));

    if (value.length === 6) {
      await onVerifyCode(key, value);
    }
  };

  const updateCodeVerified = (key, verified) => {
    setCodes((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        verified: verified,
      },
    }));
  };

  const onSubmitForm = () => {
    console.debug({ codes });
    console.debug('qua dovrò fare una chiamata');
    try {
      onFillContactForm && onFillContactForm();
      history.push(AppRoutes.contactSuccess());
    } catch (e) {
      console.debug({ e });
    }
  };

  return (
    <BaseModal open={open} id={'modal-contact-form'} propStyle={{ minWidth: '25rem' }}>
      <Card style={style}>
        <CardContent>
          <Col>
            <Typography variant="h3">{t('contactForm.modalTitle')}</Typography>
            <Typography variant="body" className={'subtitle'} style={{ marginTop: '1rem' }}>
              {t('contactForm.modalSubtitle')}
            </Typography>
            {_.keys(codes).includes(SEND_CODE_TYPES.EMAIL) && <InputCode type={SEND_CODE_TYPES.EMAIL} codes={codes} onUpdateCodeValue={onUpdateCodeValue} onVerifyCode={onVerifyCode} />}
            {_.keys(codes).includes(SEND_CODE_TYPES.SMS) && <InputCode type={SEND_CODE_TYPES.SMS} codes={codes} onUpdateCodeValue={onUpdateCodeValue} onVerifyCode={onVerifyCode} />}
            <Row spaceBetween mt="16px">
              <Col md={5}>
                <Button onClick={closeModal} variant={'outlined'} text={t('cancel')} size={'small'} />
              </Col>
              <Col md={5}>
                <Button onClick={onSubmitForm} disabled={onSubmitDisabled} text={t('contactForm.send')} size={'small'} />
              </Col>
            </Row>
            <Row spaceBetween alignCenter mt="16px">
              <Col>
                <p className={'notCodeYet small'}>{t('contactForm.notCodeYet')}</p>
              </Col>
              <LoadingButton variant="text" onClick={onResendVerificationCode} loading={resendLoading} disabled={resendLoading}>
                <span className={'insert-link small'}>{t('contactForm.resendCode')}</span>
              </LoadingButton>
            </Row>
          </Col>
        </CardContent>
      </Card>
    </BaseModal>
  );
};

const InputCode = ({ type, onUpdateCodeValue, codes, onVerifyCode }) => {
  const { t } = useTranslation();
  return (
    <Row fullWidth spaceBetween style={{ marginTop: '1rem' }}>
      <InputText label={t(`contactForm.insert${type}`)} fullWidth style={{ width: '70%' }} onChange={(v) => onUpdateCodeValue(type, v.target.value)} value={codes[type].value} variant={'outlined'} />
      <Col alignCenter justifyCenter>
        <Row flex alignCenter justifyEnd>
          <MuiButton variant="text" disabled={!codes[type].value} onClick={() => onVerifyCode(type)}>
            <span className={'verify-link'}>{t(codes[type].verified ? 'verified' : 'verify')}</span>
          </MuiButton>
          {codes[type].verified ? <Icon icon={'fal fa-check-circle'} color={'green'} /> : <Icon icon={'fal fa-times-circle'} color={'red'} />}
        </Row>
      </Col>
    </Row>
  );
};

import React, {memo} from "react";
import { TextField } from '@mui/material'
import clsx from 'clsx'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import './inputDatePicker.scss'
import DateAdapter from '@mui/lab/AdapterLuxon';
import {LocalizationProvider} from '@mui/lab';

export const InputDatePicker = memo(({ handleChange, value, label, helperText, error, ...props}) => (
  <LocalizationProvider dateAdapter={DateAdapter} >
    <DesktopDatePicker
      label={label}
      inputFormat="dd/MM/yyyy"
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} helperText={helperText} error={error} className={clsx('input-date-picker')}/>}
      {...props}
    />
  </LocalizationProvider>

))

import { DATE_FORMAT, FRAME_TYPE } from './const';
import { DateTime } from 'luxon';
import _ from 'lodash';
import { store } from '../app/store';
import { frameKeyFromDate } from './metrics';

export const fromMillisToFormat = (dateInMillis, format = DATE_FORMAT) => {
  if (!dateInMillis) {
    return null;
  }

  return DateTime.fromMillis(dateInMillis).toFormat(format);
};

export const fromMillisToDate = (dateInMillis) => {
  if (!dateInMillis) {
    return null;
  }

  if (dateInMillis === 0) {
    return 0;
  }

  return DateTime.fromMillis(dateInMillis);
};

export const fromDateToMillis = (jsDate) => {
  if (!jsDate) return null;

  return jsDate.toMillis();
};

export const fromDateToIso = (jsDate) => {
  if (!jsDate) return null;

  return jsDate.toISO();
};

export const dateOptionsAndValues = {
  last7days: {
    $gt: DateTime.now().minus({ days: 6 }).startOf('days').toMillis(),
    $lte: DateTime.now().endOf('days').toMillis(),
  },
  last14days: {
    $gt: DateTime.now().minus({ days: 13 }).startOf('days').toMillis(),
    $lte: DateTime.now().endOf('days').toMillis(),
  },
  lastMonth: {
    $gt: DateTime.now().minus({ weeks: 4 }).startOf('weeks').toMillis(),
    $lte: DateTime.now().toMillis(),
  },
  custom: {},
};

export const dateOptionsAndValuesFromTo = {
  last7days: {
    from: DateTime.now().minus({ days: 6 }).startOf('days').toMillis(),
    to: DateTime.now().endOf('days').toMillis(),
  },
  last14days: {
    from: DateTime.now().minus({ days: 13 }).startOf('days').toMillis(),
    to: DateTime.now().endOf('days').toMillis(),
  },
  lastMonth: {
    from: DateTime.now().minus({ weeks: 3 }).startOf('weeks').toMillis(),
    to: DateTime.now().endOf('weeks').toMillis(),
  },
  custom: {},
};

export const dateFromKey = (key, units) => {
  const splitted = _.split(key, ':');
  let unitQta;

  const [days, weeks, quarters, months, years] = splitted;

  units.apiUnit === FRAME_TYPE.DAILY && (unitQta = days);
  units.apiUnit === FRAME_TYPE.WEEKLY && (unitQta = weeks);
  units.apiUnit === FRAME_TYPE.QUARTERLY && (unitQta = quarters);
  units.apiUnit === FRAME_TYPE.MONTHLY && (unitQta = months);
  units.apiUnit === FRAME_TYPE.YEARLY && (unitQta = years);

  const date = DateTime.fromObject({ years })
    .startOf('years')
    .plus({ [units.unit]: Number(unitQta - 1) });

  if (units.unit === 'days') {
    return date.setLocale('it').weekdayShort.toString().substring(0, 1).toUpperCase() + ' ' + date.day;
  }
  if (units.unit === 'weeks') {
    return date.setLocale('it').toFormat('dd/MM');
  }
  if (units.unit === 'months') {
    return date.setLocale('it').month.toString();
  }
  if (units.unit === 'years') {
    return date.setLocale('it').year.toString();
  }
};

export const dateFromKeyGantt = (key, units) => {
  const splitted = _.split(key, ':');
  let unitQta;
  const [days, weeks, quarters, months, years] = splitted;

  if (units.apiUnit === FRAME_TYPE.DAILY) {
    unitQta = days;
    const date = DateTime.fromObject({ years })
      .startOf('years')
      .plus({ [units.unit]: Number(unitQta - 1) });
    return _.upperFirst(`${date.setLocale('it').weekdayShort} ${date.setLocale('it').toFormat('dd/MM')}`);
  }

  if (units.apiUnit === FRAME_TYPE.WEEKLY) {
    unitQta = weeks;
    const date = DateTime.fromObject({ years })
      .startOf('years')
      .plus({ [units.unit]: Number(unitQta) });
    const startOfWeek = date.startOf('weeks').toFormat('dd/MM');
    const endOfWeek = date.endOf('weeks').toFormat('dd/MM');
    return `${startOfWeek} - ${endOfWeek}`;
  }

  return dateFromKey(key, units);
};

export const ganttScaleUnitBasedOnFilters = (filters) => {
  console.debug({ filters });
  const from = fromMillisToDate(_.get(filters, 'duration.begin.ts'));
  const to = fromMillisToDate(_.get(filters, 'duration.end.ts'));

  if (!from || !to) {
    return { apiUnit: FRAME_TYPE.WEEKLY, unit: 'weeks' };
  }

  const days = _.ceil(to.diff(from, 'days').toObject().days);
  const weeks = _.ceil(to.diff(from, 'weeks').toObject().weeks);
  const months = _.ceil(to.diff(from, 'months').toObject().months);

  if (days <= 14) {
    return { apiUnit: FRAME_TYPE.DAILY, unit: 'days' };
  }
  if (days > 14 && weeks <= 12) {
    return { apiUnit: FRAME_TYPE.WEEKLY, unit: 'weeks' };
  }
  if (weeks > 12 && months <= 12) {
    return { apiUnit: FRAME_TYPE.MONTHLY, unit: 'months' };
  }
  if (months > 12) {
    return { apiUnit: FRAME_TYPE.YEARLY, unit: 'years' };
  }
};

export const normalizedDataBasedOnDates = (units, initialData, from, to) => {
  const diffObj = to.diff(from, [units.unit]).toObject();
  const numberUnits = new Array(_.round(diffObj[units.unit]));

  // console.debug({ diffObj, numberUnits });
  // console.debug({ initialData });
  const result = _.reduce(
    numberUnits,
    (acc, it, index) => {
      const dateAtIterationNumber = from.plus({ [units.unit]: index });
      const date = dateAtIterationNumber.diff(DateTime.fromObject({ years: from.year }).startOf('years'), [units.unit]);
      let key;
      // Il .weekNumber sembra non essere corretto con le domeniche
      if (units.apiUnit === FRAME_TYPE.DAILY) {
        key = `${_.ceil(date[units.unit])}:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.WEEKLY) {
        key = `*:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.MONTHLY) {
        key = `*:*:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.QUARTERLY) {
        key = `*:*:*:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
      }
      if (units.apiUnit === FRAME_TYPE.YEARLY) {
        key = `*:*:*:*:${dateAtIterationNumber.year}`;
      }

      const dataFromApi = _.find(initialData, (data) => {
        // console.debug(key, data._id.key);

        if (units.apiUnit === FRAME_TYPE.DAILY) {
          return _.isEqual(data._id.key.split(':')[0], key.split(':')[0]);
        } else {
          return _.isEqual(data._id.key, key);
        }
      });

      const obj = {
        _id: { key },
        count: 0,
        total: 0,
        totalPrice: 0,
        ...dataFromApi,
      };
      // console.debug({ dataFromApi, obj });

      return [...acc, obj];
    },
    []
  );

  console.log({ result });
  return result || [];
};

const normalizeSingleDateKey = (dateAtIterationNumber, units, date) => {
  let key;
  // Il .weekNumber sembra non essere corretto con le domeniche
  if (units.apiUnit === FRAME_TYPE.DAILY) {
    key = `${_.ceil(date[units.unit])}:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
  }
  if (units.apiUnit === FRAME_TYPE.WEEKLY) {
    key = `*:${dateAtIterationNumber.weekNumber}:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
  }
  if (units.apiUnit === FRAME_TYPE.MONTHLY) {
    key = `*:*:${dateAtIterationNumber.month}:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
  }
  if (units.apiUnit === FRAME_TYPE.QUARTERLY) {
    key = `*:*:*:${dateAtIterationNumber.quarter}:${dateAtIterationNumber.year}`;
  }
  if (units.apiUnit === FRAME_TYPE.YEARLY) {
    key = `*:*:*:*:${dateAtIterationNumber.year}`;
  }

  return key;
};

export const normalizeHomePerformance = (units, initialData, from, to) => {
  const diffObj = to.diff(from, [units.unit]).toObject();
  const numberUnits = new Array(_.round(diffObj[units.unit]));

  const result = _.reduce(
    numberUnits,
    (acc, it, index) => {
      const dateAtIterationNumber = from.plus({ [units.unit]: index });
      const key = frameKeyFromDate(dateAtIterationNumber, units.apiUnit);
      const dataFromApi = _.find(initialData, (data) => {
        if (units.apiUnit === FRAME_TYPE.DAILY) {
          return _.isEqual(data.frame.split(':')[0], key.split(':')[0]);
        } else {
          return _.isEqual(data.frame, key);
        }
      });
      const obj = {
        frame: key,
        value: 0,
        ...dataFromApi,
      };
      return [...acc, obj];
    },
    []
  );

  console.debug({ result });
  return result || [];
};

export const campaignStatisticUnitBasedOnFiltersDate = (filters) => {
  // console.debug({ campaignStatisticUnitBasedOnFilters: filters });
  const from = fromMillisToDate(filters.from);
  const to = fromMillisToDate(filters.to);

  const days = _.ceil(to.diff(from, 'days').toObject().days);

  if (days <= 14 || !from || !to) {
    return { apiUnit: FRAME_TYPE.DAILY, unit: 'days' };
  } else {
    return { apiUnit: FRAME_TYPE.WEEKLY, unit: 'weeks' };
  }
};

import axios from 'axios';
import { store } from '../app/store';
import { setUserInvitationId, userSelectors } from '../features/userSlice';
import { businessUnitSelectors, setBusinessUnitCampaigns, setBusinessUnitInvitations, setUserBusinessUnitList } from '../features/businessUnitSlice';
import { setBusinessUnitSelected } from '../features/businessUnitSlice';
import { toast } from 'react-toastify';
import { BASE_URL_SITE, TOAST } from '../utils/const';
import i18n from 'i18next';
import { translate } from '../i18n';
import { AppRoutes } from '../app/routers';
import { masterAxios } from './axios';
import _ from 'lodash';
import { addCampaignToCard, setCart } from '../features/campaignSlice';
import { normalizeCampaign } from '../utils';

export const BUSINESS_UNIT_ERROR = {
  REQUIRED: 'required',
};

export const getBusinessUnitList = async () => {
  try {
    const params = JSON.stringify({
      native: {
        editStatus: { $ne: 'deleted' },
      },
    });
    const { data } = await axios.get(`/private/business-units`, {
      params: {
        limit: -1,
        params,
      },
    });
    const myBusinessUnits = _.get(data, 'data');
    store.dispatch(setUserBusinessUnitList(myBusinessUnits));
  } catch (e) {
    console.debug({ e });
  }
};

export const getBusinessUnit = async () => {
  try {
    const params = JSON.stringify({
      native: {
        editStatus: { $ne: 'deleted' },
      },
    });
    const { data } = await axios.get(`/private/business-units`, {
      params: {
        limit: -1,
        params,
      },
    });
    console.log({ businessUnitResponse: data });
    const firstBusinessUnit = _.get(data, 'data[0]');
    const myBusinessUnits = _.get(data, 'data');
    const state = store.getState();
    const selectedBusinessUnit = businessUnitSelectors.selected(state);

    console.log({ myBusinessUnits });
    let isOkSelectedBU = false;
    if (!_.isEmpty(myBusinessUnits)) {
      isOkSelectedBU = myBusinessUnits.some((bu) => bu._id === selectedBusinessUnit._id);
      console.log({ isOkSelectedBU });
    }
    if ((_.isEmpty(selectedBusinessUnit) || !isOkSelectedBU) && firstBusinessUnit) {
      store.dispatch(setBusinessUnitSelected(firstBusinessUnit));
    }
    if (_.isEmpty(myBusinessUnits)) {
      store.dispatch(setBusinessUnitSelected({}));
      return BUSINESS_UNIT_ERROR.REQUIRED;
    }
    store.dispatch(setUserBusinessUnitList(myBusinessUnits));
  } catch (e) {
    console.log({ errorGetBusinessUnit: e });
  }
};

export const postBusinessUnit = async () => {
  const { data } = await axios.post(`/private/business-units`);
  console.log({ data });
  return data.data[0];
};

export const getBusinessUnitInfluencerDetail = async (influencerId) => {
  try {
    const { data } = await axios.get('private/influencers/others', {
      params: {
        params: JSON.stringify({
          ids: [influencerId],
        }),
      },
    });
    console.log({ getInfluencerDetail: data });
    return data.data[0];
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export const getInfluencerCampaigns = async (influencerId) => {
  try {
    const { data } = await axios.get('private/influencers/others/campaigns', {
      params: {
        params: JSON.stringify({
          ids: [influencerId],
          prj: {
            inc: true,
            fields: ['_id'],
          },
        }),
        limit: 1,
      },
    });
    return _.get(data, 'total', 0);
  } catch (e) {
    console.log({ e });
    return null;
  }
};

export const patchBusinessUnit = async (newData) => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    if (!businessUnit._id) {
      const newBusinessUnit = await postBusinessUnit();
      businessUnitId = newBusinessUnit._id;
    }
    const { data } = await axios.patch(`/private/business-units/${businessUnitId}`, newData);
    console.log({ data });
    store.dispatch(setBusinessUnitSelected(data.data[0]));
    getBusinessUnit();
    toast(translate('business unit patch successfully'), TOAST.SUCCESS);
    return true;
  } catch (e) {
    console.log({ errorPatchBusinessUnit: e });
    const error = _.get(e, 'response.data.errors[0]', '');
    if (error === 'Vat already present') {
      toast(translate('vat already present'), TOAST.ERROR);
      return;
    }
    toast(translate('business unit patch error'), TOAST.ERROR);
  }
};

export const deleteBusinessUnit = async () => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    const { data } = await axios.delete(`/private/business-units?ids=${businessUnitId}`);
    console.log({ data });
    store.dispatch(setBusinessUnitSelected({}));
    getBusinessUnit();
    toast(translate('business unit delete successfully'), TOAST.SUCCESS);
  } catch (e) {
    console.log({ errorDeleteBusinessUnit: e });
    toast(translate('business unit delete error'), TOAST.ERROR);
  }
};

export const getBusinessUnitInvitations = async () => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    if (!businessUnitId) {
      return;
    }
    const { data } = await masterAxios.get(`/private/business-units/${businessUnitId}/invitations`);
    //QUESTA RITORNA YOU ARE NOT ADMIN
    console.log({ businessUnitInvitations: data.data });
    store.dispatch(setBusinessUnitInvitations(data.data));
  } catch (e) {
    console.log({ errorGetBusinessUnitInvitations: e });
  }
};

export const checkUserInvitationAndAcceptIt = async () => {
  const state = store.getState();
  const userToken = userSelectors.token(state);
  const invitationId = userSelectors.invitationId(state);

  if (!userToken || !invitationId) {
    return;
  }

  try {
    const acceptRes = await acceptBusinessUnitInvitation(invitationId);
    console.log({ acceptRes });
  } catch (e) {
    console.log({ e });
  }
};

export const postBusinessUnitInvitation = async (mail) => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    const params = {
      businessUnitId,
      mail,
      callbackUri: `${BASE_URL_SITE}${AppRoutes.acceptCompanyInvitation()}?invitationId=%s`,
    };
    const { data } = await axios.post(`/private/business-units/${businessUnitId}/invitations`, params);
    toast(translate('business unit invite user successfully'), TOAST.SUCCESS);
    console.log({ createBusinessUser: data });
  } catch (e) {
    toast(translate('business unit invite user error'), TOAST.ERROR);
    console.log({ errorInviteUser: e });
  }
};

export const acceptBusinessUnitInvitation = async (id) => {
  const state = store.getState();
  const userToken = userSelectors.token(state);

  if (!userToken) {
    toast(translate('login to accept invitation'), TOAST.ERROR);
    return;
  }

  try {
    // const { data: getInvitationId } = await axios.get(`/private/invitations/${id}`);
    // console.log({getInvitationId})
    const { data } = await axios.put(`/private/invitations/${id}`);
    toast(translate('business unit invite accept successfully'), TOAST.SUCCESS);
    getBusinessUnitInvitations();
    getBusinessUnit();
    store.dispatch(setUserInvitationId(undefined));
    console.log({ acceptedBusinessInvitation: data });
  } catch (e) {
    toast(translate('business unit invite accept error'), TOAST.ERROR);
    store.dispatch(setUserInvitationId(undefined));
    console.log({ errorAcceptInvitation: e });
  }
};

export const deleteBusinessUnitInvitation = async (id) => {
  try {
    const { data } = await axios.delete(`/private/invitations/${id}`);
    toast(translate('business unit invite delete successfully'), TOAST.SUCCESS);
    getBusinessUnitInvitations();
    getBusinessUnit();
    console.log({ createBusinessUser: data });
  } catch (e) {
    toast(translate('business unit invite delete error'), TOAST.ERROR);
    console.log({ errorInviteUser: e });
  }
};

export const sendBusinessUnitOwnership = async (id) => {
  try {
    const state = store.getState();
    const businessUnit = businessUnitSelectors.selected(state);
    let businessUnitId = businessUnit._id;
    console.log({ businessUnitId, newOwn: id });
    const { data } = await axios.put(`/private/business-units/${businessUnitId}/user/${id}/role?role=admin`);
    console.log({ sendBusinessUnitOwnership: data });
    toast(translate('business unit invite delete successfully'), TOAST.SUCCESS);
    getBusinessUnit();
    console.log({ sendOwner: data });
  } catch (e) {
    toast(translate('business unit invite delete error'), TOAST.ERROR);
    console.log({ errorInviteUser: e });
  }
};

export const getBusinessUnitCampaigns = async () => {
  const state = store.getState();
  const businessUnit = businessUnitSelectors.selected(state);
  const businessUnitId = businessUnit._id;

  if (!businessUnitId) {
    return;
  }

  try {
    const { data } = await axios.get(`/private/business-units/${businessUnitId}/campaigns`, {
      params: {
        params: {
          sort: {
            asc: false,
            fields: ['updated.ts'],
          },
        },
      },
    });
    console.debug({ data });
    const campaignsToCard = {};
    data.data.forEach((camp) => {
      if (camp.campaignStatus === 'purchasable') {
        campaignsToCard[camp._id] = camp;
      }
    });
    console.log({ campaignsToCard });

    store.dispatch(setBusinessUnitCampaigns(data.data));
    store.dispatch(setCart(campaignsToCard));
  } catch (e) {
    console.log({ getBusinessUnitCampaignsError: e });
  }
};

import React, { useEffect, useMemo } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import './StepBudget.scss';
import { Button, Col, Icon, InputNumber, InputText, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash';
import { patchCampaign } from '../../../api';
import { campaignTypes, CONTACT_TYPES, STEP_BUDGET, STEP_CATEGORY_TIME_PLACE, STEP_SOCIAL, TOAST } from '../../../utils/const';
import { calculatedDisplayedBudget, calculateTotalCostVat, getCampaignTypeConfig, showIva } from '../../../utils';
import { campaignSelectors, setCampaignCompletedSteps, setCampaignCurrentStep } from '../../../features/campaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import { campaignValidations } from '../../../utils/validations';
import InputAdornment from '@mui/material/InputAdornment';
import { appSelectors } from '../../../features/appSlice';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import { StepBudgetPromo } from './StepBudgetPromo';
import { getAppConfig } from '../../../api/configurations';

export const StepBudget = ({ campaignType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appConfig = useSelector(appSelectors.config);
  const taxPercentage = showIva() ? _.get(appConfig, 'taxPercentage', 0) : 0;
  const campaignConfig = getCampaignTypeConfig(campaignType);
  const selectedCampaign = useSelector(campaignSelectors.selected);
  const completedSteps = useSelector(campaignSelectors.completedSteps);
  const isPayPerSales = campaignType === campaignTypes.PayPerSales;
  const percentageOnSale = _.get(campaignConfig, 'percentageOnSale', 0) * 100;
  const currency = _.get(campaignConfig, 'costPerUnit.currency');
  const promoId = _.get(selectedCampaign, 'budget.promo._id');
  const minQty = _.get(campaignConfig, 'minQty', 0);

  console.debug({ minQty });

  const baseCostEuro = useMemo(() => {
    if (campaignType === campaignTypes.PayPerContact) {
      const contactTypeCosts = {
        [CONTACT_TYPES.EMAIL]: _.get(campaignConfig, 'emailCost'),
        [CONTACT_TYPES.PHONE]: _.get(campaignConfig, 'phoneCost'),
        [CONTACT_TYPES.EMAIL_PHONE]: _.get(campaignConfig, 'emailAndPhoneCost'),
      };
      return _.get(contactTypeCosts, `[${selectedCampaign.contactType}].value`, 0);
    } else {
      return _.get(campaignConfig, 'costPerUnit.value', 0);
    }
  }, []);

  const disableForm = !!promoId;

  useEffect(() => {
    getAppConfig();
  }, []);

  const { handleSubmit, setFieldError, setFieldValue, errors, validateField, validateForm, values } = useFormik({
    initialValues: {
      extraTip: _.get(selectedCampaign, 'budget.extraTipFixed'),
      promoCode: _.get(selectedCampaign, 'budget.promo._id'),
      quantity: _.get(selectedCampaign, 'budget.quantity'),
      totalCost: _.get(selectedCampaign, 'budget.budget.value', ''), // budget
    },
    validationSchema: campaignValidations[campaignType].budget,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (data) => {
      const submitRes = await submitForm();
      if (submitRes) {
        dispatch(setCampaignCurrentStep(STEP_CATEGORY_TIME_PLACE));
        dispatch(setCampaignCompletedSteps([STEP_SOCIAL, STEP_BUDGET]));
      }
    },
  });

  const submitForm = async () => {
    try {
      const validateRes = await validateForm();
      if (values.quantity < minQty) {
      }
      console.log({ aaa: _.isEmpty(validateRes) });
      if (!_.isEmpty(validateRes)) {
        return false;
      }

      const budgetData = {
        budget: {
          costPerUnit: {
            value: baseCostEuro,
            currency: currency,
          },
          quantity: Number(values.quantity),
          promo: _.get(selectedCampaign, 'budget.promo'),
          extraTipFixed: Number(values.extraTip),
        },
      };
      console.log({ budgetData });
      await patchCampaign(selectedCampaign._id, { ...budgetData });
      return true;
    } catch (e) {
      return false;
    }
  };

  const totalCostUnique = useMemo(() => {
    if (isPayPerSales) {
      const productPrice = _.get(selectedCampaign, 'product.price.value', 0);
      return Number(values.extraTip || 0) + Number(baseCostEuro) + Number(productPrice) * (percentageOnSale / 100);
    }
    return Number(values.extraTip || 0) + Number(baseCostEuro);
  }, [values.extraTip, baseCostEuro, percentageOnSale, selectedCampaign]);

  // const totalCostVat = totalCostUnique * Number(values.quantity) * taxPercentage

  const totalCostVat = useMemo(() => {
    return calculateTotalCostVat(campaignType, values, taxPercentage, totalCostUnique);
  }, [values, totalCostUnique, taxPercentage, campaignType]);

  const displayedBudget = useMemo(() => {
    return calculatedDisplayedBudget(campaignType, values, taxPercentage, totalCostUnique, selectedCampaign.product);
  }, [values, totalCostUnique, taxPercentage, campaignType, selectedCampaign.product]);

  const totalTaxedCost = Number(displayedBudget + totalCostVat);

  const onSubmitForm = async () => {
    const errors = await validateForm(values);
    console.debug({ quantity: values.quantity, minQty });
    if (Number(values.quantity) < Number(minQty)) {
      setFieldError('quantity', `minQuantityError`);
      errors.quantity = `minQuantityError`;
    }
    console.debug({ errors });
    if (_.isEmpty(errors)) {
      handleSubmit();
    } else {
      toast(translate('check required fields'), TOAST.ERROR);
    }
  };

  const quantityError = useMemo(() => {
    if (errors.quantity === 'minQuantityError') {
      return t(`${campaignType}.budget.minQuantityError`, { minQty });
    } else return t(errors.quantity);
  }, [errors.quantity, values.quantity, minQty, campaignType]);

  const onExit = async () => {
    const res = await validateForm(values);
    if (!_.isEmpty(res)) {
      const steps = _.filter(completedSteps, (s) => s !== STEP_BUDGET);
      dispatch(setCampaignCompletedSteps(steps));
    }
    dispatch(setCampaignCurrentStep(STEP_SOCIAL));
  };

  const onChangeQuantity = async (e) => {
    await onChangeValue(e.target.value, 'quantity');
  };

  const onChangeValue = async (value, fieldName) => {
    await setFieldValue(fieldName, value);
    await validateField(fieldName);
    if (fieldName === 'quantity') {
      // console.log({ totalCost: value * totalCostUnique });
      await setFieldValue('totalCost', (value * totalCostUnique).toFixed(2));
    }
    if (fieldName === 'totalCost') {
      // console.log({ quantity: value / totalCostUnique });
      await setFieldValue('quantity', Math.floor(value / totalCostUnique));
    }
    if (fieldName === 'extraTip') {
      const newExtraTipEuro = Number(value);
      const newTotalCostUnique = baseCostEuro + newExtraTipEuro;
      await setFieldValue('totalCost', (newTotalCostUnique * values.quantity).toFixed(2));
    }
  };

  return (
    <div id={'step-budget'}>
      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t(`${campaignType}.budget.title1`)}
        </Typography>
        <Typography variant="h5">
          {t(`${campaignType}.budget.quantityLabel`)}
          <Tooltip title={t(`${campaignType}.budget.quantityTooltip`)}>
            <IconButton>
              <Icon icon={'fal fa-info-circle'} />
            </IconButton>
          </Tooltip>
        </Typography>
        <Row alignCenter>
          <Grid sm={5} md={3}>
            <InputText
              name="baseCostPerClick"
              value={`${baseCostEuro.toFixed(2)}`}
              disabled={true}
              variant={'outlined'}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid sm={5} md={6}>
            <Typography variant="body">{t(`${campaignType}.costPerUnit`)}</Typography>
          </Grid>
          <Col sm={2} md={3} flexEnd>
            <Typography variant="body">{baseCostEuro.toFixed(2)} €</Typography>
          </Col>
        </Row>
        {isPayPerSales && (
          <div>
            <Row alignCenter>
              <Grid sm={5} md={3}>
                <InputText
                  placeholder={0}
                  name={t(`${campaignType}.budget.percentageOnSale`)}
                  value={percentageOnSale.toFixed(2)}
                  variant={'outlined'}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    step: '0.1',
                    lang: 'it-IT',
                  }}
                  style={{ marginTop: '0.5rem' }}
                  type="number"
                  disabled={true}
                />
              </Grid>
              <Grid sm={5} md={6} mt={'0.5rem'}>
                <Typography variant="body">
                  {t(`${campaignType}.budget.percentageOnSale`)} ({_.get(selectedCampaign, 'product.price.value', 0)}€)
                </Typography>
              </Grid>
              <Col sm={2} md={3} flexEnd>
                <Typography variant="body">{(_.get(selectedCampaign, 'product.price.value', 0) / percentageOnSale).toFixed(2)} €</Typography>
              </Col>
            </Row>
          </div>
        )}
        <Row alignCenter>
          <Grid sm={5} md={3}>
            <InputNumber
              placeholder={0}
              name="extraTip"
              value={values.extraTip}
              onChange={(e) => onChangeValue(e.target.value, 'extraTip')}
              error={errors.extraTip}
              helperText={errors.extraTip && t('insert a valid number')}
              variant={'outlined'}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                step: '0.1',
                lang: 'it-IT',
                min: 0,
              }}
              style={{ marginTop: '0.5rem' }}
              type="number"
              inputProps={{ min: 0 }}
              disabled={disableForm}
            />
          </Grid>
          <Grid sm={5} md={6} mt={'0.5rem'}>
            <Typography variant="body">{t('extra tip')}</Typography>
          </Grid>
          <Col sm={2} md={3} flexEnd>
            <Typography variant="body">{Number(values.extraTip || 0).toFixed(2)} €</Typography>
          </Col>
        </Row>
        <Row alignCenter>
          <Grid sm={5} md={3} />
          <Grid sm={5} md={6}>
            <hr />
            <div className={'spacer'} />
            <Typography variant="h5">{t(`${campaignType}.budget.quantityTotalCost`)}</Typography>
          </Grid>
          <Col sm={2} md={3} flexEnd>
            <hr />
            <div className={'spacer'} />
            <Typography variant="h5">{totalCostUnique.toFixed(2)} €</Typography>
          </Col>
        </Row>
      </Box>

      <Box mt="16px">
        <Typography variant="h3" mb="8px">
          {t(`${campaignType}.budget.title2`)}
        </Typography>
        <Typography variant="body2">{t(`${campaignType}.budget.description2`)}</Typography>
        <Row alignCenter style={{ marginTop: 10 }}>
          <Grid sm={5} md={3}>
            <InputText
              name="quantity"
              value={values.quantity}
              variant={'outlined'}
              error={errors.quantity}
              helperText={quantityError}
              onChange={onChangeQuantity}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder={0}
              InputProps={{
                step: 1,
                lang: 'it-IT',
              }}
              type="number"
              inputProps={{ min: 0 }}
              disabled={disableForm}
            />
          </Grid>
          <Grid sm={12} md={6}>
            <Typography variant="body">{t(`${campaignType}.budget.quantity`)}</Typography>
          </Grid>
          <Grid sm={5} md={3}>
            <InputNumber
              name="totalCost"
              value={values.totalCost}
              variant={'outlined'}
              error={errors.totalCost}
              helperText={errors.totalCost && t('insert a valid number')}
              onChange={(e) => onChangeValue(e.target.value, 'totalCost')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                lang: 'it-IT',
              }}
              type="number"
              inputProps={{ min: 0 }}
              placeholder={0.0}
              disabled={disableForm}
            />
          </Grid>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Grid sm={5} md={3} />
          <Grid sm={5} md={6}>
            <Typography variant="h5">{t('total budget')}</Typography>
            <div className={'spacer'} />
            {showIva() ? <Typography variant="h5">{t('total budget with VAT')}</Typography> : <Typography variant="body2">{t('text legal note')}</Typography>}
            <div className={'spacer'} />
            <hr />
            <div className={'spacer'} />
            <Typography variant="h5">{t('total cost')}</Typography>
          </Grid>
          <Col sm={2} md={3} flexEnd>
            <Typography variant="h5">{(displayedBudget || 0).toFixed(2)} €</Typography>
            <div className={'spacer'} />
            <Typography variant="h5">{(totalCostVat || 0).toFixed(2)} €</Typography>
            <div className={'spacer'} />
            <hr />
            <div className={'spacer'} />
            <Typography variant="h5">{(totalTaxedCost || 0).toFixed(2)} €</Typography>
          </Col>
        </Row>
      </Box>

      <StepBudgetPromo campaignType={campaignType} values={values} setFieldValue={setFieldValue} onChangeValue={onChangeValue} onSubmitForm={submitForm} totalTaxedCost={totalTaxedCost} />

      <Row spaceAround mt="16px" style={{ marginTop: 20 }}>
        <Button
          onClick={onExit}
          variant={'outlined'}
          text={t('go back')}
          size={'small'}
          sx={{
            minWidth: '12rem',
          }}
        />
        <Button
          onClick={onSubmitForm}
          text={t('continue')}
          size={'small'}
          sx={{
            minWidth: '12rem',
          }}
        />
      </Row>
    </div>
  );
};

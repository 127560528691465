import React, { useMemo, useState } from 'react';
import { Card, Chip, Link, Typography } from '@mui/material';
import clsx from 'clsx';
import './CampaignCardRecap.scss';
import { Button, Col, Icon, Row } from '../../ui';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addCampaignToCard, campaignSelectors } from '../../../features/campaignSlice';
import { InfoDetailCampaign, ListSocial } from '../../../pages/admin/campaign/CampaignDetailPage';
import _ from 'lodash';
import { campaignStatus as CAMPAIGN_STATUS, campaignTypes, CONTACT_TYPES, STEP_SOCIAL } from '../../../utils/const';
import { calculatedDisplayedBudget, calculateTotalCostVat, normalizeCurrency, numberFormat, showIva } from '../../../utils';
import { CampaignModalRecapImages } from '../modals/campaignModalRecap/campaignModalRecapImages';
import { CampaignModalRecapVideo } from '../modals/campaignModalRecap/campaignModalRecapVideo';
import { CampaignModalRecapBrief } from '../modals/campaignModalRecap/CampaignModalRecapBrief';
import { useHistory } from 'react-router-dom';
import { AppRoutes, PUBLIC } from '../../../app/routers';
import { appSelectors } from '../../../features/appSlice';
import { getApiUrl } from '../../../api/axios';
import {DateTime} from 'luxon';

const CampaignCardRecap = () => {
  const campaign = useSelector(campaignSelectors.selected);
  const currentStep = useSelector(campaignSelectors.currentStep);
  const dispatch = useDispatch();
  const history = useHistory();
  const showBudget = _.get(campaign, 'budget.total.value', 0) > 0;
  // console.debug({ campaign, showBudget });
  const { t } = useTranslation();

  const onClickSave = () => {
    history.push(AppRoutes.campaignList());
  };

  const onClickAddToCart = () => {
    dispatch(addCampaignToCard(campaign));
  };

  const addToCardEnable = useMemo(() => {
    return _.get(campaign, 'campaignStatus') === CAMPAIGN_STATUS.PURCHASABLE;
  }, [campaign]);

  return (
    <Card className={clsx('b-card-recap')} sx={{ boxShadow: 3 }}>
      <Chip label={t(campaign.campaignStatus || 'draft')} className={clsx('b-card-recap__chip')} />
      <Row className={clsx('b-card-recap__titleSection')}>
        <Typography component="h3" variant="p">
          {campaign?.typeof ? t(`${campaign?.typeof}.title`) : ' - '}
        </Typography>
      </Row>
      <SocialCampaign campaign={campaign} />
      <LinkCampaign campaign={campaign} />
      <CampaignContent campaign={campaign} />
      {showBudget && <BudgetCampaign campaign={campaign} />}
      {/*<div className={clsx('b-card-recap__controls')}>*/}
      {/*  <Button className={'button left'} variant="outlined" onClick={onClickSave} size="small">*/}
      {/*    <span className={'bnt-text'}>{t('save')}</span>*/}
      {/*  </Button>*/}
      {/*  <Button className={'button right'} type="submit" disabled={!addToCardEnable} onClick={onClickAddToCart} size="small">*/}
      {/*    <span className={'bnt-text'}>{t('addToCart')}</span>*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </Card>
  );
};

export default CampaignCardRecap;

export const BudgetCampaign = ({ campaign }) => {
  const { t } = useTranslation();
  const { budget, typeof: campaignType, businessUnit } = campaign;
  const history = useHistory();
  const hasIva = _.get(campaign, 'businessUnit.billing.hasIVA');
  const appConfig = useSelector(appSelectors.config);
  const promoId = _.get(campaign, 'budget.promo._id');
  const percentageOnSale = _.get(appConfig, 'campaign[x-sales].percentageOnSale', 0);
  const isPayPerSales = _.get(campaign, 'campaignType') === campaignTypes.PayPerSales;
  const payPerSalesProductCost = _.get(campaign, 'product.price.value', 0);
  const taxPercentage = showIva() ? _.get(appConfig, 'taxPercentage', 0) : 0;
  const payPerSalesCommission = percentageOnSale * payPerSalesProductCost;
  const totalCalculatedCost = _.get(campaign, 'budget.total', 0);
  const promoName = _.get(campaign, 'budget.promo._id', '');
  const extraTip = _.get(budget, 'extraTipFixed', 0);
  const costPerUnit = _.get(budget, 'costPerUnit');
  const productPrice = _.get(campaign, 'product.price');
  const quantity = _.get(budget, 'quantity');

  // console.debug({ productPrice });

  const taxes = {
    value: hasIva ? (budget?.total ? _.subtract(_.get(budget, 'total.value', 0), _.get(budget, 'totalBeforeTaxes.value', 0)) : 0) : 0,
    currency: _.get(budget, 'total.currency', {}),
  };

  const totalCost = useMemo(() => {
    return {
      ..._.get(campaign, 'budget.budget'),
      value: _.get(campaign, 'budget.budget.value', 0) + _.get(taxes, 'value', 0),
    };
  }, [campaign.budget, taxes]);

  const totalCostUnique = useMemo(() => {
    return _.get(budget, 'totalCostPerUnit.value', 0);
    // if (isPayPerSales) {
    //   const productPriceValue = _.get(productPrice, 'value', 0);
    //   console.log({extraTip, baseCostEuro, productPrice, percentageOnSale})
    //   return Number(extraTip) + Number(costPerUnit?.value || 0) + Number(productPriceValue) * (percentageOnSale / 100);
    // } else {
    //   return Number(extraTip || 0) + Number(costPerUnit?.value || 0);
    // }
  }, [costPerUnit, isPayPerSales, productPrice, percentageOnSale, budget]);

  const totalCostVat = useMemo(() => {

    return totalCostUnique * Number(quantity) * taxPercentage;
  }, [quantity, totalCostUnique, taxPercentage]);

  const displayedBudget = useMemo(() => {
    // console.debug({ totalCostUnique, quantity });
    return totalCostUnique * Number(quantity);
  }, [totalCostUnique, quantity]);

  const totalTaxedCost = Number(displayedBudget + totalCostVat);

  if (budget && budget.budget) {
    return (
      <Col id={'budget-campaign'}>
        <InfoDetailCampaign fieldName={`${campaignType}.costPerUnit`} value={numberFormat(budget.costPerUnit.value)} unit={'€'} />
        <InfoDetailCampaign fieldName={'extra tip'} value={numberFormat(budget.extraTipFixed)} unit={'€'} />
        {isPayPerSales && (
          <InfoDetailCampaign
            fieldName={`${t(`${campaignType}.budget.percentageOnSale`)} (${percentageOnSale * 100}% ${t('of')} ${payPerSalesProductCost.toFixed(2)}€)`}
            value={numberFormat(payPerSalesCommission)}
            unit={'€'}
          />
        )}
        {!!budget?.totalCostPerUnit && <InfoDetailCampaign fieldName={`${campaignType}.budget.quantityTotalCost`} value={numberFormat(budget.totalCostPerUnit.value)} unit={'€'} />}
        <InfoDetailCampaign fieldName={`${campaignType}.budget.quantityLabels`} value={budget.quantity} />
        {budget?.budget && <InfoDetailCampaign fieldName={'total budget'} icon={'far fa-coins'} value={normalizeCurrency(budget.budget)} className={'bold'} />}
        <hr />
        {/*{hasIva && (*/}
        <div>
          {promoName && <InfoDetailCampaign fieldName={'promo code'} value={promoName} />}
          <InfoDetailCampaign fieldName={'IVA'} value={normalizeCurrency({ ...taxes, value: totalCostVat })} />
          {promoName && (
            <InfoDetailCampaign icon={'far fa-coins'} fieldName={'total cost'} value={normalizeCurrency({ value: totalTaxedCost })} className={'bold'} style={{ textDecoration: 'line-through' }} />
          )}
          <InfoDetailCampaign icon={'far fa-coins'} fieldName={promoName ? 'total cost with promo' : 'total cost'} value={normalizeCurrency(_.get(budget, 'total', {}))} className={'bold'} />
        </div>
        {/*)}*/}
        {/*<hr />*/}
      </Col>
    );
  } else {
    return <Col />;
  }
};

export const LinkCampaign = ({ campaign }) => {
  console.log({ campaignLink: campaign });
  const { t } = useTranslation();
  const history = useHistory();
  const localApiUrl = useSelector(getApiUrl);
  const style = { height: 20, width: 20 };

  const label = useMemo(() => {
    switch (campaign.typeof) {
      case campaignTypes.PayPerClick:
        return t('link');
      case campaignTypes.PayPerSales:
        return t('landing');
      case campaignTypes.PayPerContact:
        return t('PayPerContactType');
      case campaignTypes.PayPerPostEngagement:
        return t('hashtag');
      default:
        return '';
    }
  }, [campaign]);

  const text = useMemo(() => {
    switch (campaign.typeof) {
      case campaignTypes.PayPerClick:
        return _.truncate(_.get(campaign, 'advice.url.uri'), 50);
      case campaignTypes.PayPerSales:
        if (!campaign?.shortCode) {
          return `/${PUBLIC}/campaigns/product?cmpId=${campaign._id}`;
        } else {
          return `${localApiUrl}/public/proxy/${campaign?.shortCode}`;
        }

      case campaignTypes.PayPerContact:
        if (campaign.contactType === CONTACT_TYPES.EMAIL) {
          return t('PayPerContact.contact.email');
        }
        if (campaign.contactType === CONTACT_TYPES.PHONE) {
          return t('PayPerContact.contact.phone');
        }
        if (campaign.contactType === CONTACT_TYPES.EMAIL_PHONE) {
          return t('PayPerContact.contact.email-phone');
        }
        return;
      case campaignTypes.PayPerPostEngagement:
        if (!campaign.hashtag) {
          return '';
        }
        return campaign.hashtag;
      default:
        return '';
    }
  }, [campaign]);

  const isClickable = [campaignTypes.PayPerClick, campaignTypes.PayPerSales].includes(campaign?.typeof);

  // console.log({ text });

  const showProductPreview = () => {
    if (!campaign?.shortCode) {
      history.push({
        pathname: AppRoutes.product(campaign?._id || 'new'),
        state: _.get(campaign, 'product'),
      });
    } else {
      window.open(`${localApiUrl}/public/proxy/${campaign?.shortCode}`, '_blank');
    }
  };

  return (
    <div>
      {text && (
        <Row spaceBetween className={'link'}>
          <hr style={{ width: '100%' }} />
          <Row xs={5} alignCenter>
            <div style={{ width: '1.2rem', paddingRight: '4px' }}>
              <Icon icon={'far fa-home'} style={style} size="xs" />
            </div>
            <Typography component="bold">{_.toString(label)}</Typography>
          </Row>
          <Col xs={7} flexEnd justifyCenter style={{ width: 'inherit' }}>
            {/*<span className={'card-view-detail'}>{_.truncate(text, { length: 30 })}</span>*/}
            {campaign.typeof === campaignTypes.PayPerSales ? (
              <span className={'card-view-detail card-view-detail-click pointer'} onClick={showProductPreview}>
                {t('show preview')}
              </span>
            ) : (
              <span className={`card-view-detail ${isClickable && 'card-view-detail-click'}`}>{_.toString(text)}</span>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export const SocialCampaign = ({ campaign }) => {
  const campaignSocials = _.get(campaign, 'contents.influencerContent.socials');
  console.debug({ campaignSocials });
  const style = { height: '1rem', width: '1rem' };

  return (
    <div>
      {!_.isEmpty(campaignSocials) && (
        <>
          <hr />
          <Col className={'social-container'}>
            <ListSocial socials={campaignSocials} sx={{ height: '1.5rem', width: '1.5rem', marginRight: '0.5rem' }} iconStyle={style} className={'icon-social'} />
          </Col>
        </>
      )}
    </div>
  );
};

export const CampaignContent = ({ campaign }) => {
  const { contents, landingPage, brief } = campaign;
  const { influencerContent } = contents;
  const { instruction, video, images } = influencerContent;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState('');

  const onClickShowDetail = (type) => {
    console.debug(type);
    setShowModal(type);
  };

  const closeModal = () => {
    setShowModal('');
  };

  const ContentRow = ({ label, value, icon, firstMd = 3 }) => {
    return (
      <Row spaceBetween>
        <Row sm={12} md={firstMd} flexStart flex>
          <Icon icon={icon} style={{ paddingRight: '0.5rem' }} />
          <Typography variant="bold">{t(label)}</Typography>
        </Row>
        <Col flex flexStart>
          <div className={'text-max3'}>{value}</div>
        </Col>
        <Col sm={12} flexEnd md={3}>
          <span className={'card-view-detail pointer'} onClick={() => onClickShowDetail(label)}>
            {t('view detail')}
          </span>
        </Col>
      </Row>
    );
  };

  return (
    <Col>
      <CampaignModalRecapImages open={showModal === 'images'} images={images} onClose={closeModal} />
      <CampaignModalRecapVideo open={showModal === 'video'} video={video && video.uri} onClose={closeModal} />
      <CampaignModalRecapVideo open={showModal === 'instruction'} video={instruction && instruction.uri} onClose={closeModal} title={'instruction'} />
      <CampaignModalRecapBrief open={showModal === 'brief'} brief={brief} onClose={closeModal} />
      <hr />
      {!!brief && <ContentRow icon={'fas fa-newspaper'} label={'brief'} value={_.truncate(brief, { length: 40 })} firstMd={4} />}
      {!!instruction && <ContentRow icon={'fas fa-book'} firstMd={4} label={'instruction'} value={1} />}
      {!!images && !!images.length && <ContentRow icon={'fas fa-camera'} label={'images'} value={images.length} firstMd={4} />}
      {!!video && <ContentRow icon={'fas fa-video'} label={'video'} value={1} firstMd={4}/>}
      <hr />
    </Col>
  );
};


export const CategoryAndTimeCampaign = ({ campaign }) => {
  const { t } = useTranslation();

  const duration = _.get(campaign, 'duration.duration')
  const startDate = _.get(campaign, 'duration.begin.iso')
  const formattedStartDate = startDate ? DateTime.fromISO(
    _.get(campaign, 'duration.begin.iso')
  ).toFormat('dd-MM-yyyy HH:mm') : t('as soon as possible')
  // console.log({formattedStartDate, date: _.get(campaign, 'duration.begin.iso')})
  if (!duration) {
    return <div/>
  }

  return (
    <Col>
      <hr style={{ width: '100%' }} />
      <InfoDetailCampaign
        fieldName={t('category')}
        value={_.get(campaign, 'category.label', '')}
        icon={'fas fa-shopping-bag'}
      />
      <InfoDetailCampaign
        fieldName={t('campaign.duration.begin')}
        value={formattedStartDate}
        icon={'fal fa-calendar-plus'}
      />
      <InfoDetailCampaign
        fieldName={t('campaign days duration')}
        value={duration}
        icon={'fal fa-calendar-times'}
      />
    </Col>
  );

};

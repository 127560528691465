import { DATE_TIME_UNITS, DATE_TIME_UNITS_API_NEW, FRAME_TYPE } from './const';
import _ from 'lodash';
import { DateTime } from 'luxon';

export const frameKeyFromDate = (date, frameUnit) => {
  let key;

  if (frameUnit === FRAME_TYPE.DAILY) {
    key = `${date.ordinal}:${date.weekNumber}:${date.month}:${date.quarter}:${date.year}`;
  }
  if (frameUnit === FRAME_TYPE.WEEKLY) {
    key = `*:${date.weekNumber}:${date.month}:${date.quarter}:${date.year}`;
  }
  if (frameUnit === FRAME_TYPE.MONTHLY) {
    key = `*:*:${date.month}:${date.quarter}:${date.year}`;
  }
  if (frameUnit === FRAME_TYPE.QUARTERLY) {
    key = `*:*:*:${date.quarter}:${date.year}`;
  }
  if (date.apiUnit === FRAME_TYPE.YEARLY) {
    key = `*:*:*:*:${date.year}`;
  }

  return key;
};

//create empty data using filters date (from and to) and frame unit key returned by the api data
export const emptyDataFromDate = (fromDate, toDate, frameUnit, defaultValue = { cost: 0, count: 0 }) => {
  const dateTimeUnit = DATE_TIME_UNITS[frameUnit];
  const diffObj = toDate.diff(fromDate, dateTimeUnit).toObject();

  // console.debug({ dateTimeUnit, diffObj });

  const numberUnits = new Array(_.round(diffObj[dateTimeUnit]));

  // console.debug({ diffObj, numberUnits, dateTimeUnit, frameUnit });

  const result = _.reduce(
    numberUnits,
    (acc, it, index) => {
      const dateAtIterationNumber = fromDate.plus({ [dateTimeUnit]: index });
      const key = frameKeyFromDate(dateAtIterationNumber, frameUnit);
      const obj = {
        frame: key,
        frameUnit: { apiUnit: frameUnit, unit: dateTimeUnit },
        ...defaultValue,
      };
      acc.push(obj);
      return acc;
    },
    []
  );

  // console.debug({ emptyDataFromDate: result });
  return result;
};

export const normalizeDataFromApi = (metricData, frame) => {
  const dateTimeUnit = DATE_TIME_UNITS[frame];

  return _.reduce(
    metricData,
    (result, data) => {
      const frameDate = data.date.date;
      const frameQuantity = data._id.frameKey;
      const yearDate = DateTime.fromFormat(frameDate, 'yyyy-MM-dd').year;
      const date = DateTime.fromObject({ year: yearDate })
        .startOf('years')
        .plus({ [dateTimeUnit]: frameQuantity });
      console.debug({ date });
      const key = frameKeyFromDate(date, frame);

      console.debug({ key, date, dateTimeUnit, frameQuantity, formatDate: DateTime.fromFormat(frameDate, 'yyyy-MM-dd').toFormat('dd/MM/yyyy') });

      result.push({
        frame: key,
        count: data.count,
        cost: data.cost,
      });
      return result;
      // const
    },
    []
  );
};

export const mergeEmptyDataAndApiResult = (emptyData, apiResult) => {
  const mergedData = _.reduce(
    emptyData,
    (result, singleData) => {
      const emptyDataBasedOnKey = _.find(apiResult, (d) => d.frame === singleData.frame);

      const newData = {
        ...singleData,
        ...emptyDataBasedOnKey,
      };

      result.push(newData);
      return result;
    },
    []
  );

  // console.debug({ mergedData });
  return mergedData;
};

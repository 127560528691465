import React, {memo, useEffect, useMemo, useState} from 'react';
import clsx from 'clsx'
import './inputSelect.scss'
import { TextField, MenuItem } from '@mui/material'
import _ from 'lodash'
import {getCatalogue} from '../../../api';

export const InputSelect = ({
  type,
  options = [],
  optionLabel = 'label',
  label,
  onChange,
  value,
  emitValue = false,
  optionValue = '_id',
  style = { width: '90%'},
  ...props
 }) => {

  const [elements, setElements] = useState(options)

  useEffect(() => {
    console.log({options})
    setElements(options)
    if(type) {
      getCatalogue(type).then(itemRes => {
        setElements(itemRes)
      })
    }
  }, [])

  const mappedElement = useMemo(() => {
    return _.map(elements, item => ({
      label: _.get(item, `${optionLabel}`),
      value: _.get(item, `${optionValue}`),
    }))
  }, [elements])

  const handleChange = (event) => {
    event.preventDefault()
    if (typeof onChange === 'function') {

      if(emitValue) {
        onChange(event.target.value)
        return
      }

      if(type || !emitValue) {
        const item = _.find(elements, el => el._id === event.target.value)
        onChange(item)
        return;
      }

      onChange(event)

    }
  }


  return (
    <TextField
      className={clsx('input-select')}
      select
      color='primary'
      label={label}
      value={value}
      style={style}
      variant='standard'
      {...props}
      onChange={handleChange}
    >
      {mappedElement?.map((item, i) => (
        <MenuItem key={`${item.value}-${i}`} value={item.value}>
          {_.get(item, optionLabel, item.label ?? `item-${i}`)}
        </MenuItem>
      ))}
    </TextField>
  )
}

import React, { useEffect, useState, memo, useMemo } from 'react';
import './CampaignFilters.scss';
import { useTranslation } from 'react-i18next';
import { Col, Icon, InputDatePicker, InputSelect, InputText, Row } from '../../../components/ui';
import { useDispatch, useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import { Box, Grow, IconButton } from '@mui/material';
import { setCampaignFilters } from '../../../features/campaignSlice';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { getEnchorerBusinessUnits } from '../../../api/enchorer';
import { campaignStatus, campaignTypes } from '../../../utils/const';
import { Animated } from 'react-animated-css';

export const dateOptions = {
  last28Days: 'last28Days',
  thisMonth: 'thisMonth',
  last90Days: 'last90Days',
  lastMonth: 'lastMonth',
  thisYears: 'thisYears',
  custom: 'custom',
  allDate: undefined,
};

export const budgetOption = {
  allBudget: undefined,
  from1to100: { from: 1, to: 100 },
  from101to200: { from: 101, to: 200 },
  from201to400: { from: 201, to: 400 },
  from401to700: { from: 401, to: 700 },
  from701to1000: { from: 701, to: 1000 },
  from1001: { from: 1001 },
};

export const statusesOption = {
  allStatus: 'allStatus',
  ...campaignStatus,
};

//Possibilità di ordinare per budget, per tempo rimanente per l’approvazione, per azienda, data inizio e data fine.
export const sortOptions = [
  { _id: 'budget.total', label: 'budget' },
  { _id: 'duration.end.datetime.ts', label: 'endDate' },
  { _id: 'duration.begin.datetime.ts', label: 'startDate' },
];

export const campaignTypeOpt = {
  ...campaignTypes,
  allTypes: undefined,
};

const CampaignFilters = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const campaignFilters = useSelector(campaignSelectors.filters);
  const campaignTypeOptions = _.map(_.keys(campaignTypeOpt), (o) => ({
    _id: o,
    label: t(`${o}.title`),
  }));
  const campaignBudgetOptions = _.map(_.keys(budgetOption), (o) => ({
    _id: o,
    label: t(o),
  }));
  const campaignStatusesOptions = _.map(_.values(statusesOption), (o) => ({
    _id: o,
    label: t(o),
  }));
  const campaignSortOption = _.map(sortOptions, (o) => ({
    _id: o._id,
    label: t(o.label),
  }));
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [search, setSearch] = useState(_.get(campaignFilters, 'name'));
  const [showFilters, setShowFilters] = useState(true);
  const [customDates, setCustomDates] = useState({
    beginFrom: null,
    beginTo: null,
    endFrom: null,
    endTo: null,
  });
  const [showPickerDates, setShowPickerDate] = useState(_.isEqual(_.get(campaignFilters, 'date'), dateOptions.custom));

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await getEnchorerBusinessUnits(t);
    setBusinessUnitOptions(res);
  };

  const inputStyle = {
    //backgroundColor: '#D7D7D7',
    color: 'black',
    width: '18rem',
    marginInline: '1rem',
    marginBottom: '0.5rem',
  };
  const onToggleShowFilters = () => {
    setShowFilters((prevState) => !prevState);
  };
  const onChangeDateFilters = (_id) => {
    console.log({ _id });
    let now = DateTime.now().toMillis();
    let fromDate;

    if (_id === dateOptions.last28Days) {
      fromDate = DateTime.now().minus({ days: 28 }).toMillis();
    }
    if (_id === dateOptions.last90Days) {
      fromDate = DateTime.now().minus({ days: 30 }).toMillis();
    }
    if (_id === dateOptions.thisMonth) {
      fromDate = DateTime.now().startOf('months').toMillis();
    }
    if (_id === dateOptions.lastMonth) {
      fromDate = DateTime.now().minus({ months: 1 }).startOf('months').toMillis();
    }
    if (_id === dateOptions.thisYears) {
      fromDate = DateTime.now().startOf('year').toMillis();
    }

    if (_id === dateOptions.custom) {
      setShowPickerDate(true);
    }

    if (_id === 'allDate') {
      fromDate = undefined;
      now = undefined;
    }

    const params = {
      date: _id,
      'duration.begin.datetime.ts': {
        $gte: fromDate,
        $lte: now,
      },
    };

    if (_id !== dateOptions.custom) {
      setShowPickerDate(false);
      dispatch(setCampaignFilters(params));
    }
  };

  const onChangeBusinessUnit = (value) => {
    dispatch(setCampaignFilters({ businessUnitId: value }));
  };

  const onChangeCampaignType = (value) => {
    dispatch(setCampaignFilters({ typeof: value }));
  };

  const onChangeCampaignBudget = (value) => {
    dispatch(setCampaignFilters({ budget: value }));
  };

  const onChangeCampaignSort = (value) => {
    const params = {
      sort: {
        asc: !_.isEqual(value, 'budget.total'),
        fields: [value],
      },
    };
    dispatch(setCampaignFilters(params));
  };

  const onChangeCampaignStatus = (value) => {
    dispatch(setCampaignFilters({ campaignStatus: value }));
  };

  const onChangeCustomDate = (value, dateField) => {
    setCustomDates({ ...customDates, [dateField]: value });
  };

  const onChangeCampaignCode = (e) => {
    const value = e.target.value;
    setSearch(value);
    dispatch(setCampaignFilters({ name: value }));
  };

  return (
    <Col>
      <Row id={'campaign-filters'} alignCenter spaceBetween>
        <IconButton className={'button'} onClick={onToggleShowFilters}>
          <Icon icon={showFilters ? 'far fa-times' : 'far fa-filter'} color={'white'} sx={{ padding: '0.5rem' }} style={{ width: '1.5rem', height: '1.5rem' }} />
        </IconButton>
        <Row flex spaceBetween container md={12}>
          <Col md={9}>
            <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={showFilters}>
              <InputSelect
                variant={'outlined'}
                label={t('date')}
                emitValue
                value={_.get(campaignFilters, 'date')}
                onChange={(value) => onChangeDateFilters(value)}
                options={_.map(_.keys(dateOptions), (o) => ({
                  _id: o,
                  label: t(o),
                }))}
                style={inputStyle}
              />
              {!_.isEmpty(businessUnitOptions) && (
                <InputSelect
                  variant={'outlined'}
                  label={t('businessUnit')}
                  emitValue
                  value={_.get(campaignFilters, 'businessUnitId')}
                  onChange={(value) => onChangeBusinessUnit(value)}
                  options={businessUnitOptions}
                  style={inputStyle}
                />
              )}
              <InputSelect
                variant={'outlined'}
                label={t('campaign type')}
                emitValue
                value={_.get(campaignFilters, 'typeof')}
                onChange={(value) => onChangeCampaignType(value)}
                options={campaignTypeOptions}
                style={inputStyle}
              />
              <InputSelect
                variant={'outlined'}
                label={t('campaign budget')}
                emitValue
                value={_.get(campaignFilters, 'budget')}
                onChange={(value) => onChangeCampaignBudget(value)}
                options={campaignBudgetOptions}
                style={inputStyle}
              />
              <InputSelect
                variant={'outlined'}
                label={t('campaignStatus')}
                emitValue
                value={_.get(campaignFilters, 'campaignStatus')}
                onChange={onChangeCampaignStatus}
                options={campaignStatusesOptions}
                style={inputStyle}
              />
              <InputText variant={'outlined'} label={t('campaignCode')} emitValue value={search} onChange={onChangeCampaignCode} style={inputStyle} />
            </Animated>
          </Col>
          <Col alignCenter justifyCenter>
            <Animated animationIn="bounceInRight" animationOut="fadeOut" isVisible={showFilters}>
              <InputSelect variant={'outlined'} label={t('sort')} emitValue onChange={(value) => onChangeCampaignSort(value)} options={campaignSortOption} style={inputStyle} />
            </Animated>
          </Col>
        </Row>
      </Row>
      {showPickerDates && (
        <Row flex container md={12}>
          <Col item md={3}>
            <InputDatePicker label={t('from')} value={customDates.beginFrom} handleChange={(newDate) => onChangeCustomDate(newDate, 'beginFrom')} minDate={DateTime.fromObject({ year: 2020 })} />
          </Col>
          <Col item md={3}>
            <InputDatePicker label={t('to')} value={customDates.endTo} handleChange={(newDate) => onChangeCustomDate(newDate, 'endTo')} minDate={DateTime.fromObject({ year: 2020 })} />
          </Col>
        </Row>
      )}
    </Col>
  );
});

export default CampaignFilters;

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { campaignSelectors, editCampaignSelected } from '../../../features/campaignSlice';
import { Col, ImageContainer, InputFile, Row } from '../../ui';
import { Typography } from '@mui/material';
import React from 'react';
import './StepSocial.scss';

export const UploadImages = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const campaign = useSelector(campaignSelectors.selected);
  const images = campaign?.contents?.influencerContent?.images;

  const onUploadImage = (uploaded) => {
    const contents = {
      ...campaign.contents,
      influencerContent: {
        ...campaign.contents.influencerContent,
        images: [
          ...images,
          {
            mimeType: 'image/png',
            uri: uploaded,
            toUpload: true,
          },
        ],
      },
    };
    dispatch(editCampaignSelected({ contents }));
  };

  const onClickDelete = (imageToDelete) => {
    const newImages = images.filter((image) => (image?.uri || '') !== (imageToDelete?.uri || ''));
    const contents = {
      ...campaign.contents,
      influencerContent: {
        ...campaign.contents.influencerContent,
        images: newImages,
      },
    };
    dispatch(editCampaignSelected({ contents }));
  };

  return (
    <Col>
      <Typography variant="h5" mt={'2rem'} mb={'1rem'}>
        {t(`images`)}
      </Typography>
      <Typography variant="body2" mb={'1rem'}>
        {t('upload images description')}
      </Typography>
      <InputFile onUpload={onUploadImage} resizeToStandard disabled={images.length === 10} accept={'.png,.jpeg,.jpg,.webp'} multiple />
      <Row sm={12}>
        {images && images.length && images.map((image) => (
          <ImageContainer image={image} onClickDelete={onClickDelete} />
        ))}
      </Row>
    </Col>
  );
};

import React, {memo} from "react";
import { TextField } from '@mui/material'
import clsx from 'clsx'
import './inputDateTimePicker.scss'
import DateAdapter from '@mui/lab/AdapterLuxon';
import {LocalizationProvider} from '@mui/lab';
import DateTimePicker from '@mui/lab/DateTimePicker';

export const InputDateTimePicker = memo(({ handleChange, value, label, helperText, error, ...props}) => (
  <LocalizationProvider dateAdapter={DateAdapter} >
    <DateTimePicker
      label={label}
      inputFormat="dd/MM/yyyy HH:mm"
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} helperText={helperText} error={error} className={clsx('input-date-time-picker')}/>}
      {...props}
    />
  </LocalizationProvider>

))

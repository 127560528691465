import React from 'react';
import './TaskTemplate.scss';
import _ from 'lodash';
import { Row, Col } from '../../ui';
import { AppRoutes } from '../../../app/routers';
import {campaignStatus} from '../../../utils/const';

const TaskTemplate = (item, campaign, t, history) => {
  const onClickTemplate = (e) => {
    e.preventDefault();
    history.push(AppRoutes.dashboardCampaignStatics(campaign._id));
  };

  const totalClicks = _.get(campaign, 'budget.quantity', 0) * _.get(campaign, 'completionRate', 0)
  const showGrey = _.get(campaign, 'campaignStatus') === campaignStatus.TO_BE_APPROVED
  console.log({showGrey, campaign})
  // console.log({campaign, item})
  return (
    <div
      className={`custom-task ${_.get(campaign, 'campaignStatus')}`}
      style={{ width: `${item.taskSize.width}px`, 'background-color':  showGrey ? 'grey' : undefined }}
      onClick={onClickTemplate}
    >
      <div className="custom-task-background" style={{width: `${item.taskData.progress * 100}%`}}></div>
      <div className="inner-custom-task">
        <div className="custom-task-wrapper">
          <Row fullWidth spaceBetween>
            <Col>
              <div className="custom-task-title">{_.get(campaign, 'name')}</div>
              <div className="custom-task-typeof">
                <span className={'typeof'}>
                  {t(`${_.get(campaign, 'typeof')}.title`)}
                </span>
                <span>
                  {totalClicks.toFixed(0)}/
                  {_.get(campaign, 'budget.quantity', 0)}{' '}
                  {t(`${campaign?.typeof}.unit`)}
                </span>
              </div>
            </Col>
            <Col>
              <div className="custom-task-title">
                {(_.get(campaign, 'completionRate', 0)*100).toFixed(2)}
                {'% '}
                {t('of budget').toLowerCase()}
              </div>
            </Col>
          </Row>
        </div>
        {/*<div*/}
        {/*  className="custom-task-progress"*/}
        {/*  style={{ width: `${item.taskData.progress * 1000}%` }}*/}
        {/*/>*/}

      </div>

    </div>
  );
};

export default TaskTemplate;

import React, { useEffect, useState } from 'react';
import './InfluencerCampaignPage.scss';
import { Col, Icon, Row } from '../../components/ui';
import { useParams } from 'react-router-dom';
import { getInfluencerInfo } from '../../api';
import _ from 'lodash';
import { Image } from '../../components/ui/image/image';
import { Typography } from '@mui/material';
import { theme } from '../../utils';
import { AvatarIcon, LogoLongTransparent } from '../../assets';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from '../../api/axios';
import { store } from '../../app/store';
import { campaignTypes } from '../../utils/const';

const InfluencerCampaignPage = () => {
  const [data, setData] = useState();
  const { t } = useTranslation();
  let { infNickname } = useParams();

  useEffect(() => {
    const getData = async (infNickname) => {
      if (!infNickname) return;
      const infData = await getInfluencerInfo(infNickname);
      setData(infData);
    };
    getData(infNickname);
  }, [infNickname]);

  const onClickCampaign = (selectedCampaign) => {
    const state = store.getState();
    if (selectedCampaign.campaignType !== campaignTypes.PayPerPostEngagement) {
      window.open(`${getApiUrl(state)}/public/proxy/${selectedCampaign.shortCode || selectedCampaign.shortcode}`);
    }
  };

  const onClickSocial = (campaign, social) => {
    console.log({campaign, social})
    if (social._id === '/Facebook/fa-facebook-f') {
      window.open(`https://www.facebook.com/${campaign.facebookPostId}`)
    }
    if (social._id === '/Instagram/fa-instagram') {
      let id = campaign.instagramPostId
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
      let remainder;
      let newPostId = '';

      while (id > 0) {
        let remainder = id % 64;
        id = (id - remainder) / 64;
        console.log({id})
        // const a = remainder + Number(newPostId)
        // console.log({a, aa: Number(newPostId)})
        newPostId = newPostId + alphabet[remainder]
      }
      console.log({newPostId})

    }
  }

  const campaigns = _.get(data, 'campaigns', []).filter(camp =>
    (camp.campaignType !== campaignTypes.PayPerPostEngagement ||
      (camp.campaignType === campaignTypes.PayPerPostEngagement && (camp.facebookPostId || camp.instagramPostId))))

  return (
    <div id={'influencer-page'}>
      <Col md={10} sm={12} xs={12} noFlex fullWidth>
        <img src={LogoLongTransparent} className={'logo-transparent'} alt={''} />
        <Row className={'inf-header'} alignCenter spaceBetween>
          <div className={'header-first'}>
            <Image className={'inf-avatar'} source={AvatarIcon} path={_.get(data, 'avatar.uri')} />
            <span className={'inf-nickname'}>@{_.get(data, 'nickname', '')}</span>
          </div>
          <img src={LogoLongTransparent} className={'logo-transparent-long'} alt={''} />
          {/*<img src={LogoTransparent} className={'logo-transparent'} alt={''} />*/}
        </Row>
      </Col>
      <Col md={10} sm={10} xs={10} noFlex fullWidth>
        <Row className={'inf-about'}>
          <Col>
            <Typography variant="h3" color={theme['primaryColor']}>
              {t('aboutMe')}
            </Typography>
            <div className={'inf-aboutMe'}>{_.get(data, 'about', ' - ')}</div>
          </Col>
        </Row>
        <Typography variant="h3" color={theme['primaryColor']}>
          {t('infMyCampaign')}
        </Typography>
        <Col className={'inf-campaigns'}>{campaigns.map((campaign) => <InfluencerCampaignRow campaign={campaign} onClickCampaign={onClickCampaign} onClickSocial={onClickSocial}/>)}</Col>
      </Col>
    </div>
  );
};

export default InfluencerCampaignPage;

const InfluencerCampaignRow = ({ campaign, onClickCampaign, onClickSocial }) => {

  const showSocials = campaign.campaignType === campaignTypes.PayPerPostEngagement
  const socials = campaign.socials || []

  return (
    <Row alignCenter spaceBetween className={`inf-cmp-row ${showSocials && 'inf-cmp-row-engagement'}`} onClick={() => onClickCampaign(campaign)}>
      <Row alignCenter xs={9}>
        <Image
          className={'inf-img'}
          source={_.get(campaign, 'bunAvatar.uri')}
          path={_.get(campaign, 'bunAvatar.uri')}
          uri={_.get(campaign, 'bunAvatar.uri')}
        />
        <span>{_.get(campaign, 'bunName')}</span>
      </Row>
      {/*<Row/>*/}
      {showSocials && <Row xs={3} md={2} justifyEnd>
        {socials.map(social => {
          return (
              <Icon onClick={() => onClickSocial(campaign, social)} size={'2x'} icon={`fab ${social.values[1]}`} className={'primary'} style={{marginLeft: 10}} />
            )
        })}
      </Row>}
    </Row>
  );
};

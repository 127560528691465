import React, { useEffect, useState } from 'react';
import './CampaignDetailPage.scss';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { campaignSelectors } from '../../../features/campaignSlice';
import { deleteCampaign } from '../../../api';
import { Button, Col, Icon, Row } from '../../../components/ui';
import { enchorerApproveCampaign, enchorerDismissCampaign, getEnchorerCampaignDetail } from '../../../api/enchorer';
import { CampaignModalDismiss, ConfirmModal } from '../../../components/layout/modals';
import _ from 'lodash';
import { IconVkBlack } from '../../../assets';
import { campaignStatus, TOAST } from '../../../utils/const';
import { dismissMode } from '../../../components/layout/modals/CampaignModalDismiss';
import { toast } from 'react-toastify';
import { translate } from '../../../i18n';
import clsx from 'clsx';
import { CampaignDetail } from '../../../components/layout/campaign/CampaignDetail';
import eventBus, { EVENT_BUS_ACTION } from '../../../utils/eventBus';
import { CampaignCartRow } from '../../../components/layout/campaign/CampaignCartRow';

const CampaignDetailPage = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const campaignSelected = useSelector(campaignSelectors.selected);
  const { budget, brief } = campaignSelected;
  const socials = _.get(campaignSelected, 'contents.influencerContent.socials') || [];
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  console.log({ socials, campaignSelected });
  let { id } = useParams();
  console.log({ id });

  useEffect(() => {
    if (id) {
      getEnchorerCampaignDetail(id);
    }
  }, []);

  const onShowDismissModal = () => setShowDismissModal(true);
  const onCloseDismissModal = () => setShowDismissModal(false);
  const onShowApproveModal = () => setShowApproveModal(true);
  const onCloseApproveModal = () => setShowApproveModal(false);

  const onApproveCampaign = async () => {
    await enchorerApproveCampaign(campaignSelected._id);
    eventBus.$emit(EVENT_BUS_ACTION.ITEM_CHANGED, null);
  };

  const onDismissCampaign = async (mode, notes) => {
    console.log({ mode, notes });
    try {
      if (mode === dismissMode.DELETE) {
        await deleteCampaign(campaignSelected._id);
        eventBus.$emit(EVENT_BUS_ACTION.ITEM_DELETED, null);
        history.goBack();
        toast(translate('campaign delete success'), TOAST.SUCCESS);
      } else {
        await enchorerDismissCampaign(campaignSelected._id, notes);
        eventBus.$emit(EVENT_BUS_ACTION.ITEM_CHANGED, null);
      }
    } catch (e) {
      toast(translate(`campaign ${mode} error`), TOAST.SUCCESS);
    }
  };

  const onGoBack = () => {
    history.goBack();
  };

  const Action = () => {
    const campaignSelected = useSelector(campaignSelectors.selected);
    const status = _.get(campaignSelected, 'campaignStatus', '');

    return (
      <Row spaceBetween className={'action-container'}>
        <Col flex>
          <Button variant={'outlined'} fullWidth text={t('go back')} onClick={onGoBack} />
        </Col>
        <Col flex className={'button-bottom'}>
          {status === campaignStatus.TO_BE_APPROVED && (
            <Button variant={'outlined'} fullWidth text={t('dismiss')} disabled={_.isEqual(campaignSelected.campaignStatus, campaignStatus.DISMISSED)} onClick={onShowDismissModal} />
          )}
        </Col>
        <Col flex>
          {status === campaignStatus.TO_BE_APPROVED && (
            <Button text={t('approve')} disabled={_.isEqual(campaignSelected.campaignStatus, campaignStatus.APPROVED)} fullWidth onClick={onShowApproveModal} />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <div id={'campaign-detail-page'}>
      <CampaignModalDismiss open={showDismissModal} onClose={onCloseDismissModal} onConfirm={onDismissCampaign} />
      <ConfirmModal title={t('approve campaign')} subtitle={t('sure to approve campaign')} open={showApproveModal} onClose={onCloseApproveModal} onConfirm={onApproveCampaign} />
      <CampaignDetail action={<Action />} />
    </div>
  );
};

export default CampaignDetailPage;

export const ListSocial = ({ socials, className, sx = { height: 15, width: 15 }, iconStyle, showSeparator, rowStyle }) => {
  //check su campagne vecchie che hanno socials come id
  if (_.isString(socials[0])) {
    return <Col />;
  }
  return (
    <Col>
      {showSeparator && <hr />}
      <Row className={'social-container'} style={rowStyle}>
        {socials &&
          socials?.map((social) =>
            social.label === 'VKontakte' ? (
              <div style={sx}>
                {' '}
                <img src={IconVkBlack} alt={social._id} style={iconStyle} />
              </div>
            ) : (
              <Icon icon={`fab ${social.values[1]}`} sx={sx} className={className} style={iconStyle} />
            )
          )}
      </Row>
    </Col>
  );
};

export const InfoDetailCampaign = ({ fieldName, value, icon, className, unit, style }) => {
  const { t } = useTranslation();
  return (
    <Row container spaceBetween className={'info-container'}>
      <div style={{ width: '1.5rem' }}>{icon && <Icon icon={icon} />}</div>
      <Col flexStart flex>
        <div className={clsx(className)}>{t(fieldName)}</div>
      </Col>
      <Col flexEnd>
        <span className={className} style={style}>
          {value}
          {!!unit && unit}
        </span>
      </Col>
    </Row>
  );
};

import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Header } from '../components/layout/header';
import { Auth } from './auth';
import { store } from './store';
import { userSelectors } from '../features/userSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CreateCompanyPage } from '../pages/CreateCompanyPage';
import LoginPage from '../pages/onBoarding/login/LoginPage';
import ResetPasswordPage from '../pages/onBoarding/resetPassword/ResetPassword';
import SignupCompletePage from '../pages/onBoarding/signupComplete/SignupComplete';
import SignupVerifyPage from '../pages/onBoarding/signunVerify/SignupVerify';
import CreateCampaignPage from '../pages/campaign/CreateCampaignPage';
import SentEmailPage from '../pages/onBoarding/sentEmail/SentEmailPage';
import AcceptCompanyInvitation from '../pages/AcceptCompanyInvitation';
import CartPage from '../pages/cart/CartPage';
import CampaignListPage from '../pages/admin/campaign/CampaignListPage';
import CampaignDetailPage from '../pages/admin/campaign/CampaignDetailPage';
import MyCampaignPage from '../pages/campaign/MyCampaignPage';
import LandingPageCreator from '../components/layout/modals/LandingPageCreator';
import CartPageDetail from '../pages/cart/CartPageDetail';
import SocialConfiguratorPage from '../pages/campaign/SocialConfiguratorPage';
import DebugPage from '../pages/DebugPage';
import CampaignStatisticPage from '../pages/dashboard/CampaignStatisticPage';
import ProductPage from '../pages/public/ProductPage';
import LandingPage from '../pages/public/LandingPage';
import HomePage from '../pages/HomePage';
import ProfilePage from '../pages/profile/ProfilePage';
import ContactFormPage from '../pages/public/ContactFormPage';
import ContactFormSuccess from '../pages/public/ContactFormSuccess';
import InfluencerCampaignPage from '../pages/public/InfluencerCampaignPage';
import SupportPage from '../pages/support/SupportPage';
import CashoutListPage from '../pages/admin/cashout/CashoutListPage';
import PaymentSuccess from '../pages/public/PaymentSuccess';
import CampaignSales from '../pages/campaign/CampaignSales';
import InfluencerListPage from '../pages/admin/influencers/InfluencerListPage';
import InfluencerDetailPage from '../pages/admin/influencers/InfluencerDetailPage';
import { BusinessUnitListPage } from '../pages/admin/businessUnits/BusinessUnitListPage';
// import InfluencerBunDetailPage from '../pages/dashboard/InfluencerBunDetailPage';

export const PUBLIC = 'public';
export const ENCHORER = 'enchorer';

export const AppRoutes = {
  home: () => '/home',
  profile: () => '/profile',
  login: () => '/login',
  createCompany: () => `/create-company`,
  campaign: (cmpId = ':cmpId') => `/campaigns/${cmpId}`,
  campaignSales: (cmpId = ':cmpId') => `/campaigns/${cmpId}/sales`,
  myCampaigns: () => `/campaigns`,
  completeSignup: () => `/complete-signup`,
  verifySignup: () => '/verify-signup',
  resetPassword: () => '/reset-password',
  sendEmail: () => '/send-email',
  landingPageCreator: () => '/landing-creator',
  socialConfigurator: () => '/social-configurator',
  acceptCompanyInvitation: () => '/accept-company-invitation',
  cart: () => '/cart',
  cartDetail: (id = ':id') => `/cart/detail/${id}`,
  dashboardCampaignStatics: (id = ':id') => `/campaigns/${id}/statistics`,
  // influencerBunDetail: (infId = ':infId') => `/campaigns/statistics/influencer/${infId}`,
  support: () => '/support',
  //ENCHORER
  campaignList: () => `/${ENCHORER}/campaigns`,
  campaignDetail: (id = ':id') => `/${ENCHORER}/campaigns/detail/${id}`,
  cashoutList: () => `/${ENCHORER}/cashout/list`,
  influencerList: () => `/${ENCHORER}/influencers/list`,
  influencerDetail: (infId = ':infId') => `/${ENCHORER}/influencers/detail/${infId}`,
  businessUnitList: () => `/${ENCHORER}/businessUnits/list`,
  //PUBLIC
  product: () => `/${PUBLIC}/campaigns/product`,
  influencer: (infNickname = ':infNickname') => `/inf/${infNickname}`,
  contactForm: () => `/${PUBLIC}/campaigns/contactForm`,
  contactSuccess: () => `/${PUBLIC}/campaigns/contact/success`,
  paymentSuccess: () => `/${PUBLIC}/campaigns/payment/success`,
  landing: (landingId = ':landingId') => `/${PUBLIC}/campaigns/landing/${landingId}`,
  debug: () => `/${PUBLIC}/debug`,
  fromApp: (infId = ':infId') => `/${infId}`,
};

export const Routes = () => {
  return (
    <BrowserRouter basename={'/'}>
      <Auth />
      <Header />
      <ToastContainer />
      <Switch>
        <Route exact path={AppRoutes.home()} component={HomePage} />
        <Route exact path={AppRoutes.support()} component={SupportPage} />
        <Route exact path={AppRoutes.dashboardCampaignStatics()} component={CampaignStatisticPage} />
        {/*<Route exact path={AppRoutes.influencerBunDetail()} component={InfluencerBunDetailPage} />*/}
        <Route exact path={AppRoutes.completeSignup()} component={SignupCompletePage} />
        <Route exact path={AppRoutes.verifySignup()} component={SignupVerifyPage} />
        <Route exact path={AppRoutes.verifySignup()} component={SignupVerifyPage} />
        <Route exact path={AppRoutes.cart()} component={CartPage} />
        <Route exact path={AppRoutes.cartDetail()} component={CartPageDetail} />
        <Route exact path={AppRoutes.myCampaigns()} component={MyCampaignPage} />
        {/*PUBLIC*/}
        <Route exact path={AppRoutes.product()} component={ProductPage} />
        <Route exact path={AppRoutes.landing()} component={LandingPage} />
        <Route exact path={AppRoutes.debug()} component={DebugPage} />
        <Route exact path={AppRoutes.influencer()} component={InfluencerCampaignPage} />
        <Route exact path={AppRoutes.contactForm()} component={ContactFormPage} />
        <Route exact path={AppRoutes.contactSuccess()} component={ContactFormSuccess} />
        <Route exact path={AppRoutes.paymentSuccess()} component={PaymentSuccess} />
        <Route path={AppRoutes.profile()} component={ProfilePage} />
        <Route path={AppRoutes.login()} component={LoginPage} />
        <Route path={AppRoutes.resetPassword()} component={ResetPasswordPage} />
        <Route path={AppRoutes.sendEmail()} component={SentEmailPage} />
        <Route path={AppRoutes.createCompany()} component={CreateCompanyPage} />
        <Route path={AppRoutes.acceptCompanyInvitation()} component={AcceptCompanyInvitation} />
        <Route path={AppRoutes.landingPageCreator()} component={LandingPageCreator} />
        <Route path={AppRoutes.socialConfigurator()} component={SocialConfiguratorPage} />
        <Route path={AppRoutes.campaignSales()} component={CampaignSales} />
        <Route path={AppRoutes.campaign()} component={CreateCampaignPage} />
        <EnchorerRoute path={`/${ENCHORER}`}>
          <Switch>
            <Route path={AppRoutes.campaignDetail()} component={CampaignDetailPage} />
            <Route path={AppRoutes.influencerDetail()} component={InfluencerDetailPage} />
            <Route exact path={AppRoutes.influencerList()} component={InfluencerListPage} />
            <Route exact path={AppRoutes.businessUnitList()} component={BusinessUnitListPage} />
            <Route exaxt path={AppRoutes.campaignList()} component={CampaignListPage} />
            <Route exaxt path={AppRoutes.cashoutList()} component={CashoutListPage} />
          </Switch>
        </EnchorerRoute>
        <Route
          exact
          path={AppRoutes.fromApp()}
          component={() => {
            window.location.replace('https://www.enchora.com');
            return null;
          }}
        />
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={AppRoutes.home()} />;
          }}
        />
        <Route
          path="*"
          component={() => {
            window.location.replace('https://www.enchora.com');
            return null;
          }}
        />
      </Switch>
    </BrowserRouter>
  );
};
function EnchorerRoute({ children, ...rest }) {
  const state = store.getState();
  console.log({ children });
  const userToken = userSelectors.token(state);
  const role = userSelectors.role(state);
  console.log({ userToken, role });
  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        userToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ children, ...rest }) {
  return <Route {...rest} exact render={({ location }) => children} />;
}

function MainRoute({ children, ...rest }) {
  return <Route {...rest} exact render={({ location }) => children} />;
}
